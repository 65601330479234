@import "bootstrap/scss/functions.scss";
// All variables should be overwritten here
// Set DRX variables
$petrol: #66c0cd !default;
$aqua: #48e9ff !default;
$black: #000 !default;
$txt: #666 !default;
$gray: #8ea1ac !default;
$gray-light: #c7d1d9 !default;
$yellow: #f8da00 !default;
$olive: #b5cc18 !default;
$violet: #6435c9 !default;
$purple: #a333c8 !default;
$pink: #e03997 !default;
$pink-bright: #f513fe !default;
$brown: #c0873a !default;
$tan: #f4d7ae !default;

// Overwrite bootstrap color variables
$primary: #0097ac !default;
$secondary: #597280 !default;
$success: #5eb342 !default;
$info: #007bff !default;
$warning: #f28b00 !default;
$danger: #e72d34 !default;
$light: #f1f2f3 !default;
$dark: #212121 !default;
$white: #fff !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "white": $white,
) !default;

$drx-colors: (
  "petrol": $petrol,
  "aqua": $aqua,
  "black": $black,
  "txt": $txt,
  "gray": $gray,
  "gray-light": $gray-light,
  "yellow": $yellow,
  "olive": $olive,
  "violet": $violet,
  "purple": $purple,
  "pink": $pink,
  "brown": $brown,
  "tan": $tan,
  "pink-bright": $pink-bright,
);
$theme-colors: map-merge($theme-colors, $drx-colors);

$body-color: $txt !default;
$headings-color: $dark !default;
$bg-layout-color: $white !default;
$btn-disabled-opacity: 0.45 !default;

//Breadcrumb Overwrites
$breadcrumb-font-size: 1rem;
$breadcrumb-padding-y: 0.875rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding-x: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-bg: $light;
$breadcrumb-divider-color: $txt;
$breadcrumb-active-color: $txt;
$breadcrumb-divider: quote("/");
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: 4px;

// Button Overwrites
$btn-padding-y: 16px !default;
$btn-padding-x: 32px !default;
$btn-font-size: 14px !default;
$btn-line-height: 1 !default;
$btn-border-radius: 0rem !default;

$btn-padding-y-sm: 7.5px !default;
$btn-padding-x-sm: 19.5px !default;
$btn-font-size-sm: 14px !default;
$btn-line-height-sm: 1 !default;
$btn-border-radius-sm: 0rem !default;

$btn-padding-y-lg: 14px !default;
$btn-padding-x-lg: 43.5px !default;
$btn-font-size-lg: 20px !default;
$btn-line-height-lg: 1 !default;
$btn-border-radius-lg: 0rem !default;

$btn-box-shadow: 3px 3px 4px rgba($dark, 0.15);
// $btn-focus-box-shadow: 3px 3px 4px rgba($dark, 0.15);
// $btn-active-box-shadow: 4px 4px 5px rgba($dark, 0.125);
$btn-active-box-shadow: unset;
$btn-focus-box-shadow: unset;
$btn-box-shadow: unset;
$btn-focus-width: 0;

//Modal Overwrites
$modal-content-bg: $light;
$modal-content-border-color: none;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-header-border-width: 1px;
$modal-footer-border-width: 0;
$modal-header-border-color: $gray-light;
$modal-footer-border-color: none;

//Table Overwrites
$table-color: $dark;

$table-striped-bg: $light;
$table-hover-bg-factor: 0.075;
$table-hover-bg: rgba($primary, $table-hover-bg-factor);

$table-striped-order: even;
$table-group-separator-color: $gray-light;

//Input Overwrites
$input-btn-padding-x: 1rem !default;
$input-btn-padding-y: 0.75rem !default;
$input-btn-line-height: 1;
$form-label-color: $dark !default;

$input-btn-padding-y-lg: 0.75rem !default;

//Form-Range Overwrites
$form-range-track-height: 0.1rem !default;
$form-range-track-bg: $light !default;
$form-range-thumb-bg: $secondary !default;
$form-range-thumb-active-bg: shade-color($light, 20%);
$input-range-focus-width: 0.1rem;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);

//Form-Floating Overwrites
$form-floating-label-transform: scale(0.85) translateY(-0.5rem) translateX(0rem);
$form-floating-padding-x: 1rem;

//Form-Feedback Overwrites
$form-feedback-warning-color: $warning;
$form-feedback-icon-warning-color: $form-feedback-warning-color;
$form-feedback-invalid-color: $danger;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-warning: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16' fill='none' stroke='#{$form-feedback-icon-warning-color}'><path d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z' fill='#{$form-feedback-icon-warning-color}' stroke='none'/><path d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z' fill='#{$form-feedback-icon-warning-color}' stroke='none'/></svg>");
$form-feedback-icon-invalid: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='3 3 10 10'  fill='#{$form-feedback-icon-invalid-color}' ><path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

//Navbar Overwrites
$navbar-nav-link-padding-x: 1.75rem;
$navbar-padding-y: 0rem;
$navbar-toggler-focus-width: 0;

.navbar-toggler {
  margin: 0 1rem;
}

.navbar .nav-link {
  border-bottom-color: transparent !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
  padding: 1.5rem $navbar-nav-link-padding-x;
  &:hover,
  &.active {
    color: $primary !important;
    border-bottom-color: $primary !important;
  }

  &:hover {
    background-color: rgb(0 151 172 / 5%) !important;
  }
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: $primary !important;
}

@mixin navlink($border-radius, $white, $navbar-transparent) {
  &.active-top,
  &.nav-link-top:hover {
    background: rgba(0, 0, 0, $navbar-transparent);
    border-bottom: $white solid 5px !important;
    border-top: transparent solid 5px !important;
    border-radius: $border-radius $border-radius 0 0;
    height: 100% !important;
  }
}

// General Overwrites
$border-color: $gray-light;
$enable-shadows: true !default;

$min-contrast-ratio: 2 !default;
$enable-shadows: true !default;
$enable-negative-margins: true !default;

//Dropdown
$dropdown-divider-bg: $gray;
// Adapt font stack to prefer helvetica neue and arial
$font-family-sans-serif:
  "Helvetica Neue",
  Arial,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Noto Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;

@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_variables-dark.scss";
@import "bootstrap/scss/_mixins.scss";
@import "bootstrap/scss/_maps.scss";
@import "bootstrap/scss/_utilities.scss";

:root {
  --fa-primary-color: #0097ac;
  --fa-primary-opacity: 1;
  --fa-secondary-color: #666;
  --fa-secondary-opacity: 1;
}

// Add d-contents
$utilities: map-merge(
  $utilities,
  (
    "display":
      map-merge(
        map-get($utilities, "display"),
        (
          values: contents inline inline-block block grid table table-row
            table-cell flex inline-flex none,
        )
      ),
  )
);

$drx-validation-states: (
  "warning": (
    "color": $form-feedback-warning-color,
    "icon": $form-feedback-icon-warning,
  ),
);

$form-validation-states: map-merge(
  $form-validation-states,
  $drx-validation-states
);

$input-range-focus-box-shadow: 0 0 $input-btn-focus-blur
  $input-range-focus-width $input-btn-focus-color !default;
$form-range-thumb-focus-box-shadow:
  0 0 0 1px $light,
  $input-range-focus-box-shadow;

@each $color, $value in $theme-colors {
  .btn-#{$color}:hover,
  .btn-check:checked + .btn-#{$color},
  .btn-check:active + .btn-#{$color},
  .btn-#{$color}:active,
  .btn-#{$color}.active,
  .show > .btn-#{$color}.dropdown-toggle {
    @include button-variant($value, $value);
    color: color-contrast($value) !important;
    background-color: lighten($value, 7.5%) !important ;
    border-color: darken($value, 3%) !important;
  }

  //Icon-Toggle-Buttons
  // Uncomment the following line, if u wish to see the changing color on mouse hover
  // .btn-icon-#{$color}:hover,
  .btn-check:checked + .btn-icon-#{$color},
  .btn-check:active + .btn-icon-#{$color},
  .btn-icon-#{$color}:active,
  .btn-icon-#{$color}.active,
  .show > .btn-icon-#{$color}.dropdown-toggle {
    @include button-variant($value, $value);
    color: $value !important;
    background-color: white !important ;
    border-color: $value !important;

    .btn-check:hover + &,
    &:hover {
      color: lighten($value, 7.5%) !important;
      border-color: lighten($value, 7.5%) !important;
    }
  }
  .btn-icon.btn-outline-#{$color} {
    @include button-variant($value, $value);
    color: $value !important;
    background-color: white !important;
    border-color: $value !important;
  }

  .btn-icon.btn-outline-#{$color}:hover {
    @include button-variant($value, $value);
    color: lighten($value, 7.5%) !important ;
  }

  //Checkbox-Radio-Buttons
  .btn-checkbox-radio-#{$color}:hover,
  .btn-check:checked + .btn-checkbox-radio-#{$color},
  .btn-check:active + .btn-checkbox-radio-#{$color},
  .btn-checkbox-radio-#{$color}:active,
  .btn-checkbox-radio-#{$color}.active,
  .show > .btn-checkbox-radio-#{$color}.dropdown-toggle {
    @include button-variant($value, $value);
    color: $value !important;
    background-color: #ededed !important ;
    border-color: #c7d1d9 !important;

    .btn-check:hover + &,
    &:hover {
      color: $value !important;
      background-color: #ededed !important ;
      border-color: #c7d1d9 !important;
    }
  }
  .btn-checkbox-radio.btn-text-#{$color} {
    @include button-variant($value, $value);
    color: $value !important;
    background-color: white !important;
    border-color: #c7d1d9 !important;
  }

  .btn-checkbox-radio.btn-text-#{$color}:hover {
    @include button-variant($value, $value);
    color: lighten($value, 7.5%) !important ;
    background-color: #ededed !important ;
    border-color: #c7d1d9 !important;
  }
}

// Mixin Navbar Breakpoints
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    // stylelint-disable-next-line scss/selector-no-union-class-name
    &#{$infix} {
      @include media-breakpoint-down($next) {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;

        .nav-item {
          align-self: stretch !important;
          text-align: left !important;
        }

        .nav-link {
          padding-left: 0.75rem !important;
        }

        .navbar-toggle-icon-group {
          position: absolute;
          right: 1.25rem;
          top: 1rem;
          align-items: center;
        }

        .nav-link {
          border-bottom: none !important;
          border-right-color: transparent !important;
          border-right-width: 2px !important;
          border-right-style: solid;
          &:hover,
          &.active {
            color: $primary !important;
            border-right-color: $primary !important;
          }
        }
      }

      @include media-breakpoint-up($next) {
        .collapse-content {
          height: auto !important;
          overflow: visible !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.navbar-logo {
  height: 1.4rem !important;
}

.navbar-toggler {
  border: none !important;
  margin: 0 !important;
  padding: 0.25rem 0.5rem !important;
}

.megamenu-drop {
  position: initial !important;
}

.nav-item {
  .action-item.active,
  .action-item:hover {
    color: $primary !important;
    background-color: #e8ecef !important;
    cursor: pointer !important;
  }

  .dropdown-menu {
    max-height: calc(100vh - 120px) !important;
    overflow-x: hidden !important;
    height: auto !important;
    background-color: #e8ecef !important;
    border: none;
    border-radius: 0;
    overflow-y: auto !important;
  }

  ::-webkit-scrollbar {
    width: 5px !important;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1) !important;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray !important;
    border-radius: 3px !important;
  }
}

.submenu-bar {
  background-color: #f6f6f6 !important;

  .nav-link {
    text-align: center;
    min-width: 10rem;
    padding: 0.75rem 1.75rem !important;
    color: black !important;
    cursor: pointer;
  }
}

.megamenu {
  background-color: #e8ecef;

  .active {
    background: none !important;
  }

  .megamenu-subitem:hover,
  .megamenu-subitem.active {
    background: none;
    color: $primary;
  }
}

.nav-item-label {
  color: $primary !important;
}

// SubMenu Box Shadow
$navbar-box-shadow: 0.25rem 0.5rem 0.5rem rgba(0, 0, 0, 0.15) !default;

@mixin navbar-shadow($box-shadow) {
  box-shadow: $box-shadow;
}

.navbar-shadow {
  @include navbar-shadow($navbar-box-shadow);
}

.subitems-wrapper {
  border-left: 2px solid $primary !important;
}

.breadcrumb a {
  text-decoration: none;
}

//Dropdown Divider
.dropdown-divider {
  margin: $dropdown-divider-margin-y 1rem !important;
}

.card-subtitle {
  font-weight: 400;
  font-size: smaller;
  color: gray;
}

.search-icon {
  position: absolute;
  top: 9px;
  right: 16px;
}

.modal-header > .close {
  border: none;
  font-size: 1.5rem;
  color: $txt;
}

.sort-wrapper {
  width: 16px;
  height: 24px;
  margin-left: 5px;
  position: relative;
  cursor: pointer;

  .sort-icon {
    position: absolute;
    font-size: 14px;
    color: #bbb;

    &--up {
      top: 2px;
    }

    &--down {
      top: 4px;
    }

    &--active {
      color: #000;
    }
  }
}

@import "bootstrap/scss/dropdown.scss";
@import "bootstrap/scss/mixins.scss";
